import { Header } from "../header";

import styles from "./hero.module.scss";

const Hero = () => {
    const title = "RiskIntel Consulting";
    const description =
        "State-of-the-art solutions for finance and cybersecurity.";
    const shortInfo = "WE OFFER YOU PEACE OF MIND TODAY";
    return (
        <div className={styles.hero}>
            <Header />
            <div className={styles.mainInfo}>
                <h1 className={styles.title}> {title}</h1>
                <p className={styles.description}> {description}</p>
                <img src="/assets/diamond-shiny.gif" className={styles.heroImage} alt="Diamond GIF" width={336}  />
                <span className={styles.shortInfo}>{shortInfo} </span>
            </div>
        </div>
    );
};
export default Hero;
