import { Title } from "../../components/title";

import styles from "./whoWeAre.module.scss";
const WhoWeAre = () => {
  const nameAndrew = "Andrew Shiriaev";
  const aboutAndrew1 =
    "is a Certified Sanctions Specialist (ACSS), an internal audit and compliance professional with 20 years of hands-on experience in one of the biggest international financial corporations of the world - Citigroup.";
  const aboutAndrew2 =
    "While currently the Vice President of Citibank, he has held many different roles in Citigroup's second line of defense (independent control functions), including sanctions risk management, for the past seven years.";
  const aboutAndrew3 =
    "Andrew led the development of multiple innovations in the field, including the market-first implementation of instant payments flow sanctions control, a methodology of in-depth sanctions risk analysis, and standard screening process optimization techniques.";

  const nameWolf = "Wolf Alexanyan";
  const aboutWolf1 =
    "is a cognitive and behavioral science expert who has worked in IT for over 16 years. For many years, he led a special unit operating in stealth mode on highly classified governmental software projects in the Middle East";
  const aboutWolf2 =
    "During his career, he developed complex solutions such as lawful cyber intelligence systems, digital forensics labs, and data warehousing storages operating with billions of events per day. He has also co-authored algorithms and methodologies for working with big data that consider the subtleties of human psychology.";
  const aboutWolf3 =
    "Currently acts as a COO and CPO at one of the biggest cybersecurity companies in crypto space - Hexens.io.";
  const aboutWolf4 =
    "Author of the world’s biggest open-source library of nudging strategies - UX Core";
  const uxcoreLink = "https://uxcore.io";

  const handleClick = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <section className={styles.wrapper}>
      <Title title={"Who We Are"} />
      <div>
        <div className={styles.firstPart}>
          <div>
            <p className={styles.txt}>
              <span className={styles.name}> {nameAndrew}</span> {aboutAndrew1}
            </p>
            <p className={styles.txt}> {aboutAndrew2}</p>
            <p className={styles.txt}> {aboutAndrew3}</p>
          </div>
          <img src={"/assets/A.svg"} alt="a" className={styles.aImg} />
        </div>
        <div className={styles.secondPart}>
          <div>
            <p className={styles.txt}>
              <span className={styles.name}> {nameWolf}</span> {aboutWolf1}
            </p>
            <p className={styles.txt}> {aboutWolf2}</p>
            <p className={styles.txt}> {aboutWolf3}</p>
            <p className={styles.txt}>
              {aboutWolf4}
              <span
                className={styles.link}
                onClick={() => handleClick(uxcoreLink)}
              >
                ({uxcoreLink})
              </span>
            </p>
          </div>
          <img src={"/assets/W.svg"} alt="w" className={styles.wImg} />
        </div>
        <div className={styles.imgWrapper}>
          <img
            className={styles.img}
            src={"/assets/whoWeAre.png"}
            width={1145}
            height={278}
            alt="who we are"
          />
          <img
            className={styles.imgMobile}
            src={"/assets/whoWeAreMobile.png"}
            width={600}
            height={278}
            alt="who we are"
          />
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
