import { Title } from "../../components/title";
import PartnersContainer from "../../components/partnersContainer/partnersContainer";
import styles from "./Partners.module.scss";

const Partners = () => {
  const eleaLogo = "/assets/elea.png";
  const eleaLink = "https://elea.co";
  const eleaDescription1 =
    "- an exceptional team of professionals specialized in covering all the phases of complex software development.";
  const eleaDescription2 =
    "Complete business analysis, PRDs, UI-UX Design, Prototyping, Tender management, Project management environment setup, as well as company/product audit and consulting.";

  const hexensLogo = "/assets/hexens.png";
  const hexensLink = "https://hexens.io";
  const hexensDescription =
    "With over $55 billion secured for their customers, Hexens is the best choice for covering all cybersecurity needs for your products, companies, and blockchains. You can learn more on their website, here: ";

  return (
    <div className={styles.partnersWrapper}>
      <Title title={"Our Partners"} />
      <div className={styles.wrapper}>
        <PartnersContainer
          link={eleaLink}
          logo={eleaLogo}
          description1={eleaDescription1}
          description2={eleaDescription2}
          width={159}
          height={44}
        />
        <PartnersContainer
          link={hexensLink}
          logo={hexensLogo}
          description1={hexensDescription}
          isHexens
          width={152}
          height={42}
        />
      </div>
    </div>
  );
};

export default Partners;
