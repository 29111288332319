import { FC, useEffect, useState } from "react";
import Contacts from "../contacts";

import styles from "./header.module.scss";

interface HeaderProps {
  openContacts?: (prev: boolean) => void;
}

const Header: FC<HeaderProps> = ({ openContacts }) => {
  const [openContactsModal, setOpenContactsModal] = useState(false);

  useEffect(() => {
    if (openContactsModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "unset";
    }
  }, [openContactsModal]);
  return (
    <>
      <header className={styles.header}>
        <img
          src={"/assets/logo.svg"}
          width={169}
          height={38}
          alt={"logo"}
          className={styles.logo}
        />
        <button
          className={styles.btn}
          onClick={() => {
            setOpenContactsModal(true);
          }}
        >
          Contacts
        </button>
      </header>
      {openContactsModal && <Contacts onClick={setOpenContactsModal} />}
    </>
  );
};

export default Header;
