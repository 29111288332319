import { FC } from "react";
import styles from "./title.module.scss";

type TitleProps = {
  title: string;

}

const Title: FC<TitleProps> = ({ title }) => {
  return (
    <h2 className={styles.title}>{title}</h2>
  );
}

export default Title;