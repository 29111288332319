import { FC } from "react";

import styles from "./serviceContainer.module.scss";

type ServiceContainerProps = {
  icon: string;
  title: string;
  description: string;
};

const ServiceContainer: FC<ServiceContainerProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className={styles.serviceWrapper}>
      <img
        src={icon}
        width={32}
        height={32}
        alt={title}
        className={styles.icon}
      />
      <div className={styles.titleWrapper}>
        <figure className={styles.border}> </figure>
        <span className={styles.title}> {title} </span>
      </div>
      <p className={styles.description}> {description} </p>
    </div>
  );
};

export default ServiceContainer;
