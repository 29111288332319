import { FC } from "react";
import { createPortal } from "react-dom";

import styles from "./contacts.module.scss";

type ContactsProps = {
  onClick?: (prev: boolean) => void;
};
const Contacts: FC<ContactsProps> = ({ onClick }) => {
  const handleClose = () => {
    onClick && onClick(false);
  };

  return createPortal(
    <div className={styles.overlay}>
      <figure className={styles.background} onClick={handleClose} />
      <div className={styles.body}>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>Contacts</h2>
          <button onClick={handleClose} className={styles.btn}>
            <img
              src={"assets/closeBtn.png"}
              width={32}
              height={32}
              alt={"close button"}
            />
          </button>
        </div>
        <div className={styles.contentWrapper}>
          <span className={styles.tg}>
            Telegram:{" "}
            <a
              href={"https://t.me/andrewriskintel"}
              target={"_blank"}
              rel="noreferrer"
              className={styles.tgLink}
            >
              @andrewriskintel
            </a>{" "}
            |{" "}
            <a
              href={"https://t.me/riskintel"}
              target={"_blank"}
              rel="noreferrer"
              className={styles.tgLink}
            >
              @riskintel
            </a>
          </span>
          <span className={styles.email}>
            Email: <a href={"mailto:alexanyanwolf@gmail.com"} className={styles.url}>
              alexanyanwolf@gmail.com{" "}
            </a>
          </span>
        </div>
        <div className={styles.mobileContent}>
          <a
            href={"https://t.me/andrewriskintel"}
            target={"_blank"}
            rel="noreferrer"
            className={styles.tgLinkMobile}
          >
            <img
              src={"/assets/telegram-icon.png"}
              width={24}
              height={24}
              alt={"Telegram icon"}
              className={styles.tgIcon}
            />
            t.me/andrewriskintel
          </a>
          <a
            href={"https://t.me/riskintel"}
            target={"_blank"}
            rel="noreferrer"
            className={styles.tgLinkMobile}
          >
            <img
              src={"/assets/telegram-icon.png"}
              width={24}
              height={24}
              alt={"Telegram icon"}
              className={styles.tgIcon}
            />
            t.me/riskintel
          </a>
          <a
            href={"mailto:alexanyanwolf@gmail.com"}
            className={styles.mailMobile}
          >
            <img
              src={"/assets/mail-icon.png"}
              width={24}
              height={24}
              alt={"Mail icon"}
              className={styles.mailIcon}
            />
            alexanyanwolf@gmail.com
          </a>
        </div>
        <div className={styles.based}>
          <span> Based in UAE </span>
          <span> Languages: English, Russian </span>
        </div>
        <div className={styles.btnWrapper}>
          <button className={styles.closeBtn} onClick={handleClose}> Close</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Contacts;
