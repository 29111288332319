import { FC } from "react";

import styles from "./partnersContainer.module.scss";

type PartnersContainerProps = {
  logo: string;
  link: string;
  description1: string;
  description2?: string;
  isHexens?: boolean;
  width?: number;
  height?: number;
};
const PartnersContainer: FC<PartnersContainerProps> = ({
  logo,
  link,
  description1,
  description2,
  isHexens,
  width,
  height,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}>
        <img
          src={logo}
          alt="logo"
          className={styles.logo}
          width={width}
          height={height}
        />
      </div>
      <div className={styles.content}>
        <p className={styles.txt}>
          {!isHexens && (
            <a href={link} className={styles.link} target={"_blank"} rel="noreferrer">
              {link}
            </a>
          )}
          {description1}
          {isHexens && (
            <a href={link} className={styles.link} target={"_blank"} rel="noreferrer">
              {link}.
            </a>
          )}
        </p>
        {description2 && <p className={styles.txt}> {description2} </p>}
      </div>
    </div>
  );
};

export default PartnersContainer;
