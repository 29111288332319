import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div>
          <h2 className={styles.txt}>Contacts</h2>
          <div className={styles.wrapper}>
            <img src="/assets/logo.svg" alt="logo" className={styles.img} />
            <div className={styles.contacts}>
              <span className={styles.telegramTitle}> Telegram:</span>
              <a
                href={"https://t.me/andrewriskintel"}
                target={"_blank"}
                rel="noreferrer"
                className={styles.telegramLink}
              >
                https://t.me/andrewriskintel
              </a>
              <a
                href={"https://t.me/riskintel"}
                target={"_blank"}
                rel="noreferrer"
                className={styles.telegramLink}
              >
                https://t.me/riskintel
              </a>
              <p className={styles.emails}>
                Email:
                <a href={"mailto:alexanyanwolf@gmail.com"}>
                  alexanyanwolf@gmail.com{" "}
                </a>
              </p>
              <p> Based in UAE</p>
              <p>Languages: English, Russian</p>
            </div>
          </div>
        </div>
        <div>
          <img
            src="/assets/footerImg.png"
            width={348}
            height={348}
            className={styles.footerImg}
            alt="footer"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
