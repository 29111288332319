import { Title } from "../../components/title";
import ServiceContainer from "../../components/serviceContainer";
import styles from "./services.module.scss";

const Services = () => {
  const services = [
    {
      title: "Relationship screening",
      icon: "/assets/screening.png",
      description:
        "Effective identification of sanctioned names to avoid toxic relationships that bring compliance with local and international sanctions regime requirements.",
    },
    {
      title: "Transaction Screening",
      icon: "/assets/transaction.png",
      description:
        "Screening of domestic, cross-border funds transfers as well as instant or real-time payments and trade transactions against local and international sanctions lists to flag transactions subject to additional investigation and, as a result, processing delay, further reject, and block actions.",
    },
    {
      title: "Advanced Due Diligence and Researches",
      icon: "/assets/research.png",
      description:
        "Individual and comprehensive investigation of clients, transactional and contract (including supporting documentation) data to identify potential risks of sanctions violations. Provide details for justified decision making.",
    },
    {
      title: "Securities Transactions and Holdings",
      icon: "/assets/file.png",
      description:
        "Sanctions screening of market deals and securities holdings to meet sanctions regulatory requirements of legal entities in scope.",
    },
    {
      title: "Cybersecurity Audit",
      icon: "/assets/cybersecurity.png",
      description:
        "Assess company and software systems’ vulnerabilities through simulated attacks to identify security weaknesses. Provide recommendations to enhance the overall cybersecurity posture.",
    },
    {
      title: "Smart Contracts Audit (Blockchain)",
      icon: "/assets/blockchain.png",
      description:
        "Review and verify the security and functionality of blockchain-based contracts. Ensure the integrity and reliability of automated transactions.",
    },
  ];
  return (
    <div className={styles.servicesWrapper}>
      <Title title={"Services"} />
      <div className={styles.services}>
        {services.map((service, index) => {
          return (
            <ServiceContainer
              key={index}
              title={service.title}
              icon={service.icon}
              description={service.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Services;
