import Hero from "../src/components/hero/hero";
import WhoWeAre from "../src/sections/whoWeAre";
import Services from "./sections/services";
import Partners from "./sections/Partners";
import { Footer } from "./components/footer";

import styles from "./App.module.scss";

function App() {
  return (
    <div className="App">
      <Hero />
      <main className={styles.main}>
        <WhoWeAre />
        <Services />
        <Partners />
      </main>
      <Footer />
    </div>
  );
}

export default App;
